const convertUrlsForProd = (url: string) => {
  if (!window) return;

  return url.replace("{domain}", window.location.hostname);
};

const isProd = process.env.APP_BUILD_MODE === "production";
export const GLOBALS = {
  apiUrl: isProd
    ? convertUrlsForProd(process.env.APP_API_URL)
    : process.env.APP_API_URL,
  graphqlUrl: isProd
    ? convertUrlsForProd(process.env.APP_API_GRAPHQL)
    : process.env.APP_API_GRAPHQL,
};
