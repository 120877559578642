import { axiosInstance } from "./axios";

export const priceUpdate = async (params: {
  clusterId: number;
  storeId: number;
  formFile: File;
}) => {
  const formadata = new FormData();
  formadata.append("clusterId", params.clusterId.toString());
  formadata.append("storeId", params.storeId.toString());
  formadata.append("formFile", params.formFile, params.formFile.name);

  await axiosInstance.post(`/prices/update`, formadata);

  return { isOk: true };
};
