import { gql } from "@apollo/client";

export const GET_CLUSTERS = gql`
  query clusters {
    clusters {
      id
      name
      warehouses
    }
  }
`;
