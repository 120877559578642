import { GLOBALS } from "../../globals";
import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
} from "axios";
import { userStore } from "../../store/user.store";
import { commonLogout } from "../../hooks/useAuth";
import { toast } from "sonner";

const onRequest = (config: InternalAxiosRequestConfig) => {
  if (userStore()?.token) {
    config.headers["Authorization"] = "Bearer " + userStore().token;
  }

  return config;
};

const onResponseError = async (error: AxiosError) => {
  const hasAuthErrors = [401, 403].includes(error.response.status);

  if (hasAuthErrors) {
    commonLogout();
  }

  toast.error(error.message);
};

const setupInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, undefined);
  axiosInstance.interceptors.response.use(undefined, onResponseError);

  return axiosInstance;
};

export const axiosInstance = setupInterceptors(
  axios.create({
    baseURL: GLOBALS.apiUrl,
  }),
);
